<script>
import APIStore from '@app/services/API/Store';

export default {
  data() {
    return {
      q: '',
      isFetching: false,
      customers: {
        data: [],
      },
    };
  },
  validations: {
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    getCustomers() {
      this.isFetching = true;
      APIStore.getCustomers(
        this.authStore.uuid,
        {
          q: this.q || undefined,
          has_affiliate: 0,
          customer_type: 'STUDENT',
        },
      )
        .then((data) => (this.customers = data))
        .finally(() => (this.isFetching = false));
    },
  },
  created() {
    this.getCustomers();
  },
};
</script>

<template>
  <form @submit.prevent.stop="!isFetching && getCustomers()">
    <b-field label="Rechercher">
      <b-input
          placeholder="Rechercher par email, prénom, nom ou tag"
          v-model="q"
      />
    </b-field>

    <b-skeleton v-if="isFetching" height="400" />
    <template v-else>
      <ul v-if="customers.data.length > 0">
        <li
          v-for="customer in customers.data"
          :key="customer.uuid"
          class="box flex-between-center"
        >
          <div>
            <p class="has-text-weight-bold">
              {{ customer.firstname }}
              {{ customer.lastname }}
            </p>
            <p>
              {{ customer.email }}
            </p>
          </div>
          <b-button class="ml-5" @click="$emit('choose', customer)">
            Sélectionner
          </b-button>
        </li>
      </ul>
      <p v-else class="notification">
        Pas de résultat.
      </p>
    </template>
  </form>
</template>
